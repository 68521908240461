


















































import Component, { mixins } from 'vue-class-component';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import Speaker from '@/models/graphql/Speaker';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import { Prop } from 'vue-property-decorator';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import { Getter } from 'vuex-class';
import CommunityFeature from '@/models/graphql/CommunityFeature';

@Component({
  components: {
    FontAwesomeComponent,
    ButtonIconComponent,
    AvatarSoloWidget,
  },
})
export default class SpeakerCmsHeaderWidget extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  @Prop({ required: false })
  private route!: string;

  @Getter
  private featureByKey!: (key: FeatureKeys) => CommunityFeature;

  private get speaker(): Speaker | null {
    if (this.authUser && this.authUser.speakers.length) {
      // eslint-disable-next-line prefer-destructuring
      Object.assign(this.$route.params, { speakerId: this.authUser.speakers[0].uid });
      return Speaker.hydrate(this.authUser.speakers[0]);
    }
    return null;
  }

  private get imgSrc(): string | null {
    return this.speaker
    && this.speaker.photoFileResource
    && this.speaker.photoFileResource.path
      ? FileResourceHelper.getFullPath(this.speaker.photoFileResource, 'w56')
      : null;
  }

  created(): void {
    if (!(this.featureByKey(FeatureKeys.COMMUNITY_SPEAKER_HUB_FEATURE)
          && this.featureByKey(FeatureKeys.COMMUNITY_SPEAKER_HUB_FEATURE).enabled)) {
      this.$router.push({ name: 'not-found' });
    }
    this.setDataConfig();
  }

  private onViewProfile(): void {
    if (this.speaker) {
      this.$router.push({ name: 'speaker-detail', params: { speakerId: this.speaker.uid } });
    }
  }
}
